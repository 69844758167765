/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import Img from 'gatsby-image';

import { theme } from '../../style/theme';

import { H1, H3, H4 } from '../../style/atoms/Headings';
import ImageCaptionParagraph from '../../style/atoms/ImageCaptionParagraph';

import MyHelmet from '../../style/components/MyHelmet';

import { Breadcrumb, CrumbLink } from '../../style/molecules/Breadcrumb';

import GlobalStyle from '../../assets/styles/GlobalStyle';
import { RegularSection } from '../../style/organisms/Sections';
import { StyledHeader } from '../../style/organisms/Header';
import Footer from '../../style/organisms/Footer';

const Main = styled.main``;

const Callout = styled.p`
  background-color: #f9f9fa;
  padding: 2rem;
`;

const FourImagesContainer = styled.div`
  background-color: #fafafa;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'drafts'
    'draft'
    'suggestions'
    'result';
  grid-gap: 0.6rem;
  margin-top: 1rem;
  padding: 0.6rem;
  text-align: center;
  font-size: 0.8rem;

  ${({ theme }) => theme.media.minPhone} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'drafts draft'
      'drafts suggestions'
      'result result';
  }
`;

const TwoVideosContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  font-size: 0.8rem;
  text-align: center;

  margin-top: 1rem;

  ${({ theme }) => theme.media.minRegularSection} {
    grid-template-columns: 1fr 1fr;
  }
`;

export default function Teamaton({ data }) {
  const {
    lightbulb,
    uxwriting,
    loyaltyProgram1,
    loyaltyProgram2,
    loyaltyProgram3,
    badania1,
    badania2,
    planowanie,
    systemDesign1,
    systemDesign2

  } = data;

  const myHelmetData = {
    description: 'Portfolio - Roboticket - Mateusz Kozłowski',
    domain: 'https://codeforheaven.com',
    externalScriptsUrls: [],
    facebookThumbnail: '',
    title: 'Portfolio - Roboticket - Mateusz Kozłowski',
    slug: 'portfolio/roboticket',
  };

  return (
    <>
      <MyHelmet data={myHelmetData} />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <StyledHeader>
          <H1 style={{ order: '2' }}>Roboticket</H1>
          <Breadcrumb style={{ order: '1', width: '100%', paddingLeft: '0' }}>
            <li>
              <CrumbLink to="/portfolio">Portfolio</CrumbLink>
            </li>
            <li>
              <CrumbLink to="/portfolio/teamaton">Roboticket</CrumbLink>
            </li>
          </Breadcrumb>
        </StyledHeader>
        <Main>
          <RegularSection>
            <Callout>
              <img src={lightbulb.publicURL} />{' '}
              <i>
                Firma Roboticket tworzy aplikację do ticketingu i kontroli dostępu na dużych wydarzeniach sportowych. Klientami firmy są polskie kluby piłkarskie, żużlowe oraz reprezentacje narodowe.
              </i>
            </Callout>
            <p>
              Ze względu na bieżący charakter wymienionych poniżej prac, nie mogę zaspokoić ciekawości czytelnika bezwstydnymi szczegółami lub screenshotami. Postaram się jednak opowiedzieć o swojej pracy w najbardziej przystępny sposób.
            </p>
            <h2>
              Słowem wstępu
            </h2>
            <p>
            W mojej pracy jest wiele bieżących zadań do wykonania takich, jak przełożenie projektu klienta na nasz design system, naprawa błędów, poprawa czegoś "na szybko". Nie są to zbyt UXowe zadania, jednak ze swojej strony traktuje je jako okazja do zrobienia czegos więcej.
            </p>
            <p>
              Staram się zawsze pozostawić zastany kod w lepszym stanie niż przed rozpoczęciem pracy. To przede wszystkim refaktoryzacja, usuwanie powtórzeń, poprawa czytelności (dzięki Linter!) 
            </p>
            <p>
              "Przy okazji" staram się zatroszczyć o inne, bardziej złożone sprawy: 
            </p>
            <p>
              <ul>
                <li>aktualizacja design systemu,</li>
                <li>poprawa ux writing,</li>
                <li>badania użytkowników,</li>
                <li>projektowanie nowej wersji Roboticket.</li>
              </ul>
            </p>
            <p>
              <img src={systemDesign1.publicURL} />
              <img src={systemDesign2.publicURL} />
            </p>
            <h2>Konsultacje z klientami</h2>
            <p>
              Jako osoba odpowiedzialna za interfejs i doświadczenie w aplikacji Roboticket, jestem w częstym kontakcie z przedstawicielami klubów sportowych. Pomagam im dopasować komunikację wizualną ich organizacji do możliwości naszej aplikacji i design systemu.
            </p>
            <p> 
              Praktycznie każda nowa współpraca to propozycje nowych funkcjonalności do wdrożenia: system lojalnościowy, obsługa płatności, czy odpowiednia komunikacja zmieniających się zaleceń dotyczących epidemii Covid-19 (żeby wymienić tylko te ostatnie).
            </p>
            <p> 
              Mimo, że wiele tych rozwiązań jest wprowadzanych "na już", staram się dowiedzieć w danym temacie jak najwięcej - pytam klienta lub product ownera o sens, kontekst wykorzystywania, adresatów nowej funkcjonalności. Szukam też jak najwięcej dobrych praktyk w literaturze, artykułach i społeczności UXowej.
            </p>
            <p> 
              Przykładowo, przy okazji zmian w systemie lojalnościowym zaproponowałem rozszerzenie prostego systemu zliczającego ilość zdobytych punktów. Do istniejącego rozwiązania dodałem czynnik nagrody, przynależności społecznej oraz współzawodnictwa. Starałem się też ułatwić członkowi programu lojalnościowego podjęcie kolejnych kroków, takich jak zakup kolejnego biletu. Projekt czeka w tej chwili na wdrożenie.
            </p>
            <FourImagesContainer>
              <img src={loyaltyProgram1.publicURL} />
              <img src={loyaltyProgram2.publicURL} />
              <img src={loyaltyProgram3.publicURL} />
            </FourImagesContainer>
            <h2>
              Analiza Design Systemu
            </h2>
            <TwoVideosContainer>
            <div>
            <p style={{textAlign: "left"}}>
              W ciągu kilkunastu lat istnienia produktu na rynku w aplikacji rozwinął się własny design system oparty w dużej mierze na wczesnej wersji Material Design. Moja praca na tym polu polega na analizie i modernizacji zastanych komponentów, szablonów oraz widoków. Celem moich prac jest nie tylko kosmetyczna poprawa estetyki starego systemu, ale dopasowania widoków do standardów dostępności.
            </p>
            <p style={{textAlign: "left"}}>
              W tym celu w trakcie zapoznawania się z systemem, tworzę wizualne notatki i makiety, a te z kolei układam w sieć połączeń, dzięki której mogę w łatwiejszy sposób zauważyć wzajemne powiązania i miejsca, które wymagają ujednolicenia. Staram się, aby z każdym deployem trafiała paczka zmian, która poprawi nasz design system.
            </p>
            </div>
              <img src={planowanie.publicURL} />
            </TwoVideosContainer>
            <h2>Poprawa UX Writing aplikacji</h2>
            <p>
              Aplikacja wspiera wiele języków, co jest ogromnym atutem w porównaniu z innymi dostępnymi rozwiązaniami na rynku. Jak dotąd nie poświęcano niestety odpowiedniej uwagi na dobranie odpowiednich tekstów, dlatego uznałem, że powinienem zająć się tym obszarem. 
            </p>
            <p><img src={uxwriting.publicURL} /></p> 
            <p> 
              Pracę nad poprawą napisów rozpocząłem od stworzenia widocznej powyżej mapy wszystkich tekstów znajdujących się w panelu administracyjnym systemu. Dzięki temu, mogę w łatwy sposób analizować szerszy kontekst, odnajdywać ukryte analogie i wreszcie - mogę znaleźć właściwy tekst.
            </p>
            <p> 
              Niestety na chwilę obecną zmiany obejmują języki, którymi posługuję się biegle: polski i angielski. Pozostałe tłumaczenia są "skazane" na automatycznego translatora.
            </p>
            <h2>Badania użytkowników</h2>
            <p>
              Uważam, że dobre zrozumienie użytkowników to klucz do sukcesu. Swoje poszukiwania rozpocząłem od przeszukania istniejących baz danych użytkowników i transakcji. Szukam przede wszystkim informacji demograficznych, dzięki którym będę mógł dalej sugerować kolejne przypuszczenia.
            </p>
            <p><img src={badania2.publicURL} /></p> 
            <p>
              Teoretycznie, jaki jest kibic każdy widzi, ale w aby mieć jakiś punkt odniesienia, swoją pracę badawczą rozpocząłem od stworzenia proto-person końcowych użytkowników naszego oprogramowania.
            </p>
            <p><img src={badania1.publicURL} /></p> 
            <h2>Projektowanie nowego Roboticket</h2>
            <p> Aplikacja jest w trakcie architektonicznej transformacji. W związku z tym firma, zdecydowała się zaprojektować wiele widoków od nowa. Moja praca polega na zebraniu wymagań, potrzeb użytkowników, zaprojektowaniu interaktywnych makiet (Figma), wdrażaniu styli do nowej aplikacji oraz prowadzeniu prac z developerami. </p> 
          </RegularSection>
        </Main>
        <Footer>
          <Breadcrumb style={{ paddingLeft: '0' }}>
            <li>
              <CrumbLink to="/portfolio">Portfolio</CrumbLink>
            </li>
            <li>
              <CrumbLink to="/portfolio/roboticket">Roboticket</CrumbLink>
            </li>
          </Breadcrumb>
        </Footer>
      </ThemeProvider>
    </>
  );
}

export const pageQuery = graphql`
  query {
    lightbulb: file(name: { eq: "icon__lightbulb" }) {
      publicURL
    }
    uxwriting: file(name: { eq: "ux-writing" }) {
      publicURL
    }
    loyaltyProgram1: file(name: { eq: "loyalty-program-1" }) {
      publicURL
    }
    loyaltyProgram2: file(name: { eq: "loyalty-program-2" }) {
      publicURL
    }
    loyaltyProgram3: file(name: { eq: "loyalty-program-3" }) {
      publicURL
    }
    loyaltyProgram4: file(name: { eq: "loyalty-program-4" }) {
      publicURL
    }
    planowanie: file(name: { eq: "planowanie-1" }) {
      publicURL
    }
    badania1: file(name: { eq: "badania-1" }) {
      publicURL
    }
    badania2: file(name: { eq: "badania-2" }) {
      publicURL
    }
    systemDesign1: file(name: { eq: "system-design-1" }) {
      publicURL
    }
    systemDesign2: file(name: { eq: "system-design-2" }) {
      publicURL
    }
  }
`;
